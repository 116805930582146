import { Component, OnInit } from '@angular/core';
import { BingwahApp } from '../../models/bingwah-app';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'frontend-apps-page',
  templateUrl: './apps-page.component.html',
  styleUrls: ['./apps-page.component.scss']
})
export class AppsPageComponent implements OnInit {

  // apps: BingwahApp[] = [];

  constructor(
    public ds: DataService
  ) { }

  ngOnInit() {
    this.ds.getData('apps');
    // this.ds.state.apps.source.docs
  }
  
  fetchApps(): void{
    /* this.apps.push(
      {
        _id: '1234',
        url: 'https://gbc-app-bd1c0.firebaseapp.com',
        title: 'GBC',
        description: 'Global Brain Cache'
      },
      {
        _id: '1235',
        url: 'https://kea.bingwah.com',
        title: 'KEA',
        description: 'Accommodation App'
      }
    ); */
  }
}
