import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'frontend-service-view',
  templateUrl: './service-view.component.html',
  styleUrls: ['./service-view.component.scss']
})
export class ServiceViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
