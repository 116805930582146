import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';

import { APIRequest } from '../utils/api.request';
import { APIResponse } from '../utils/api.response';
import { APIData } from '../utils/api.data';
import { BingwahApp } from '../models/bingwah-app';
import { ClientOrder } from '../models/client-order';
import { Product } from '../models/product';
import { UserAvatar } from '../models/user-avatar';

import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  state: {
    apps: {
      data: MatTableDataSource<BingwahApp>;
      source: APIData;
    };
    products: {
      data: MatTableDataSource<Product>;
      source: APIData;
    };
    orders: {
      data: MatTableDataSource<ClientOrder>;
      source: APIData;
    }
  };

  loadingData: boolean = false;

  constructor(
    private api: APIService
  )
  {
    this.state = {
      apps: {
        data: new MatTableDataSource([]),
        source: null
      },
      products: {
        data: new MatTableDataSource([]),
        source: null
      },
      orders: {
        data: new MatTableDataSource([]),
        source: null
      }
    };
  }

  getData(uri: string, limit = null, page = null): void{
    let request: APIRequest = {
      query: {
        page: page,
        limit: limit
      },
      params: uri,
      data: null
    }
    this.loadingData = true;
    this.api.getRequest(request).subscribe(response=>{
      if(response && response.data){
        this.state[uri].data = new MatTableDataSource(response.data.docs);
        this.state[uri].source = response.data;
        this.loadingData = false;
      }
      console.log('get response->', response);
    })
  }

  postData(uri: string, data: Product | BingwahApp | ClientOrder): void{
    let request: APIRequest = {
      query: null,
      params: uri,
      data: data
    }
    this.loadingData = true;
    this.api.postRequest(request).subscribe(response=>{
      if(response && response.data){
        // this.state[uri] = response.data
      }
      this.loadingData = false;
      console.log('post response->', response);
    })
  }

  putData(uri: string, data: Product | BingwahApp | ClientOrder): void{
    let request: APIRequest = {
      query: null,
      params: uri,
      data: data
    }
    this.loadingData = true;
    this.api.putRequest(request).subscribe(response=>{
      if(response && response.data){
        // this.state[uri] = response.data
      }
      this.loadingData = false;
      console.log('put response->', response);
    })
  }

  deleteData(uri: string): void{
    let request: APIRequest = {
      params: uri,
      query: null,
      data: null
    }
    this.loadingData = true;
    this.api.deleteRequest(request).subscribe(response=>{
      if(response && response.data){
        // this.state[uri] = response.data
      }
      this.loadingData = false;
      console.log('delete response->', response);
    })
  }
  
}
