import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageHeaderComponent } from './ui/page-header/page-header.component';

import { UIModule } from './ui/ui.module';
import { FooterComponent } from './ui/footer/footer.component';

import { BngMatModule } from './mat-modules/bng-mat.module';
import { DBModule } from './services/db.module';

@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    BngMatModule,
    AppRoutingModule,
    UIModule,
    DBModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},//to be able to use external links
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
