import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { User } from '../models/user';
import { APIRequest } from '../utils/api.request';
import { APIResponse } from '../utils/api.response';

import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectURL: string = '/';

  user: User;

  constructor(
    private router: Router,
    private api: APIService
  ) { }

  authUser(email: String):void {
    let authRequest: APIRequest = {
      params: 'users/auth',
      query: null,
      data: email
    }
    this.api.postRequest(authRequest).subscribe(response => {
      if(response.data){
        this.user = response.data;
        this.router.navigate([this.redirectURL])
      } else {
        this.api.logError(response.message)
      }
    })
  }
}
