import { Component, OnInit } from '@angular/core';

import { Product } from '../../models/product';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'frontend-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit {

  displayedColumns = ['title', 'description', 'details'];


  constructor(
    public ds: DataService
  ) { }

  ngOnInit() {
    this.ds.getData('products');
  }

  applyFilter(filterValue: string){
    this.ds.state.products.data.filter = filterValue.trim().toLocaleLowerCase();
  }

}
