import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
  HttpRequest
} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { APIResponse } from '../utils/api.response';
import { APIRequest } from '../utils/api.request';

import { configs } from '../../configs/configs';

const options = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': ''
  })
};

@Injectable()

export class APIService {

	private apiURL: string = `${configs.apiURL}/api/`;

  constructor(
    private http: HttpClient,
    private logger: MatSnackBar
  ) { }

  public postRequest(request: APIRequest): Observable<APIResponse>
	{
		//first check that data is ok
		if(request.data)
			request.data = this.prepData(request.data);

		//send the POST request and return response 
		let url = this.apiURL + request.params;
		return this.http.post<APIResponse>(url, request.query, options).pipe(
			tap((response_payload)=> {
				this.log('Saving...');
			}),
			catchError(this.errorHandler<APIResponse>())
		);
	}

  public getRequest(request: APIRequest): Observable<APIResponse>
	{
		//send the GET request and return response 
		let url = this.apiURL + request.params;
		return this.http.get<APIResponse>(url, options).pipe(
			tap((response_payload)=> {
				// this.log('Request Successful');
			}),
			catchError(this.errorHandler<APIResponse>())
		);
	}

  public putRequest(request: APIRequest): Observable<APIResponse>
	{
		//send the PUT request and return response 
		let url = this.apiURL + request.params;
		return this.http.put<APIResponse>(url, request.query, options).pipe(
			tap((response_payload)=> {
				this.log('Updating...');
			}),
			catchError(this.errorHandler<APIResponse>())
		);
	}

  public deleteRequest(request: APIRequest): Observable<APIResponse>
	{
		//send the request and return response 
		let url = this.apiURL + request.params;
		return this.http.delete<APIResponse>(url, options).pipe(
			tap((response_payload)=> {
				this.log('Deleting...');
			}),
			catchError(this.errorHandler<APIResponse>())
		);
	}

	/**
	* Handle failed operation.
	* Let app continue
	* @param operation - name of operation that failed
	* @param response - optional value to return as observable result
	*/

	private errorHandler<T>(operation = 'operation', response?: T)
	{
		return (error: any): Observable<T> =>{
			// TODO: send error to remote logging infrustructure
			console.log(error);

			// TODO: make error user friendly
			let error_text: string = 'Network and/or Server Error!';
			if(error.status)
			{
				 error_text = error.status == 404 ? 'Could Not Connect to Server' : error_text;
			}
			
			this.logError(`${operation} failed: ${error_text}`);

			// let app keep running; return empty result
			return of(response as T);

		}
  }
  
  /**
   * Log info
   * @param message - message to display
   */

	public log(message: string)
	{
		this.logger.open(message, 'OK', {duration: 2000});
  }
  
  /**
   * Log Error
   * @param e - error message
   */

	public logError(e: string)
	{
		this.logger.open(e, 'DISMISS', {duration: 5000});
	}

	public prepData(d)
	{
		// TODO: Validate input data:- check for SQL injection and other maliciuos data
		return JSON.parse(JSON.stringify(d));// remove undefined items
	}

}
