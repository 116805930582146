import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { DataService } from './services/data.service';

@Component({
  selector: 'frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'Bingwah Systems';

	mobileQuery: MediaQueryList;

	navLinks: any[] = [
		{ label: 'Home', path: 'home', icon: 'home' },
		{ label: 'Services', path: 'services', icon: 'list' },
		{ label: 'Apps', path: 'apps', icon: 'apps' },
		{ label: 'About', path: 'about', icon: 'info' },
	];

  private _mobileQueryListener: () => void;
  
  constructor(
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
    public ds: DataService
	)
	{
		this.mobileQuery = media.matchMedia('(max-width: 600px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	ngOnDestroy(): void
	{
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
}
