import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BngMatModule } from '../mat-modules/bng-mat.module';

import { LoginComponent } from './login/login.component';
import { ServiceListComponent } from './service-list/service-list.component';
import { ServiceViewComponent } from './service-view/service-view.component';
import { AboutComponent } from './about/about.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AppsPageComponent } from './apps-page/apps-page.component';

@NgModule({
  imports: [
    CommonModule,
    BngMatModule
  ],
  declarations: [
    LoginComponent,
    ServiceListComponent,
    ServiceViewComponent,
    AboutComponent,
    LandingPageComponent,
    AppsPageComponent,
  ]
})
export class UIModule { }
