import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './ui/about/about.component';
import { AppsPageComponent } from './ui/apps-page/apps-page.component';
import { LoginComponent } from './ui/login/login.component';
import { LandingPageComponent } from './ui/landing-page/landing-page.component';
import { ServiceListComponent } from './ui/service-list/service-list.component';

const routes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'apps', component: AppsPageComponent },
  { path: 'services', component: ServiceListComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: LandingPageComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
